import memoizeOne from "memoize-one";
import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageProps } from "@src/types";
import { t } from "@src/utils/translations";

import { BlogCardProps } from "./BlogCard";
import BlogTemplate from "./BlogTemplate";

export type BlogPageProps = PageProps & {
  data: {
    mainPost: {
      nodes: RawPostType[];
    };
    posts: {
      totalCount: number;
      nodes: RawPostType[];
    };
    authors: {
      nodes: RawAuthor[];
    };
  };
};

class BlogPage extends React.Component<BlogPageProps> {
  private getPosts = memoizeOne((data: BlogPageProps["data"]) => {
    const authors = this.normalizeAuthors(data.authors.nodes);
    const [mainPost] = data.mainPost.nodes;
    return {
      mainPost: this.normalizePost(mainPost, authors),
      posts: data.posts.nodes.map((post) => this.normalizePost(post, authors)),
    };
  });

  public render() {
    const { mainPost, posts } = this.getPosts(this.props.data);
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("Blog")}
          description={t("Get the latest posts in your email.")}
          keywords={[t("blog"), t("trucknet"), t("post"), t("subscribe")]}
        />
        <BlogTemplate mainPost={mainPost} posts={posts} />
      </IndexLayout>
    );
  }

  private normalizeAuthors = (authors: RawAuthor[]) => {
    return authors.reduce((res: { [id: string]: Author }, rawAuthor) => {
      res[rawAuthor.frontmatter.id] = rawAuthor.frontmatter;
      return res;
    }, {});
  };

  private normalizePost = (
    { frontmatter, fields }: RawPostType,
    authors: { [id: string]: Author },
  ): BlogCardProps => {
    return {
      ...frontmatter,
      slug: fields.slug,
      author: authors[frontmatter.author],
      date: new Date(frontmatter.date),
    };
  };
}

export default BlogPage;

type RawPostType = {
  frontmatter: {
    mainImage: string;
    shortPreviewContent: string;
    longPreviewContent: string;
    category: string;
    title: string;
    author: string;
    date: string;
  };
  fields: {
    slug: string;
  };
};

type Author = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
};

type RawAuthor = { frontmatter: Author };
