import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(10),
    },
    cardsContainer: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(10, -2.5, 6),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(10, 0, 6),
      },
    },
    card: {
      margin: theme.spacing(0, 2.5, 5),
      flex: "auto",
      "@media (min-width: 750px)": {
        width: "300px",
        maxWidth: "calc(50% - 40px)",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: theme.spacing(0, 0, 5),
      },
    },
    mainCard: {
      width: "100%",
      maxWidth: "100%",
    },
    fakeCard: {
      margin: theme.spacing(0, 2.5),
    },
  });
