import { Container } from "@material-ui/core";
import React from "react";

export type PageLayoutProps = {
  className?: string;
  children: React.ComponentProps<typeof Container>["children"];
};

class PageLayout extends React.PureComponent<PageLayoutProps> {
  public render() {
    const { children, className } = this.props;
    return <Container className={className}>{children}</Container>;
  }
}

export default PageLayout;
