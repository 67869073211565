import { Theme } from "@material-ui/core";
import { createStylesWithProps } from "@src/utils/styles";

import { BlogCardProps } from "./BlogCard";

const createStyles = createStylesWithProps<BlogCardProps>();

export const styles = (theme: Theme) =>
  createStyles({
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      height: "505px",
      "&:hover": {
        boxShadow: theme.shadows[10],
      },
      [theme.breakpoints.up("md")]: {
        height: ({ isMainCard }) => (isMainCard ? "469px" : "505px"),
        flexDirection: ({ isMainCard }) => (isMainCard ? "row" : "column"),
      },
      [theme.breakpoints.between(750, "sm")]: {
        height: ({ isMainCard }) => (isMainCard ? "632px" : "505px"),
      },
      [theme.breakpoints.down("xs")]: {
        height: "581px",
      },
    },
    image: {
      flex: "none",
      objectFit: "cover",
      width: "100%",
      height: "200px",
      [theme.breakpoints.between(750, "sm")]: {
        width: ({ isMainCard }) => (isMainCard ? "100%" : "100%"),
        height: ({ isMainCard }) => (isMainCard ? "315px" : "200px"),
      },
      [theme.breakpoints.up("md")]: {
        height: ({ isMainCard }) => (isMainCard ? "100%" : "200px"),
        width: ({ isMainCard }) => (isMainCard ? "51.8%" : "100%"),
      },
      [theme.breakpoints.up("lg")]: {
        width: ({ isMainCard }) => (isMainCard ? "57.1%" : "100%"),
      },
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      position: "relative",
      "&:last-child": {
        flex: "auto",
        height: "305px",
        width: "100%",
        padding: theme.spacing(5, 3, 2),
        [theme.breakpoints.up("md")]: {
          padding: ({ isMainCard }) =>
            isMainCard ? theme.spacing(10, 4, 3) : theme.spacing(5, 3, 2),
          height: ({ isMainCard }) => (isMainCard ? "100%" : "305px"),
          width: ({ isMainCard }) => (isMainCard ? "42.9%" : "100%"),
        },
        [theme.breakpoints.between(750, "sm")]: {
          width: ({ isMainCard }) => (isMainCard ? "100%" : "100%"),
          height: ({ isMainCard }) => (isMainCard ? "317px" : "305px"),
        },
        [theme.breakpoints.down("xs")]: {
          padding: theme.spacing(3),
          height: "381px",
        },
      },
    },
    category: {
      position: "absolute",
      top: theme.spacing(-2),
      [theme.breakpoints.up("md")]: {
        top: ({ isMainCard }) =>
          isMainCard ? theme.spacing(3) : theme.spacing(-2),
      },
    },
    contentContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      [theme.breakpoints.down(750)]: {
        ...theme.typography.h6,
      },
    },
    body: {
      height: "inherit",
      flex: "auto",
      overflow: "hidden",
      position: "relative",
      fontSize: "16px",
      lineHeight: "28px",
      marginBottom: ({ isMainCard }) =>
        isMainCard ? theme.spacing(5) : theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(5),
      },
      "&:after": {
        content: "''",
        width: "72px",
        height: "28px",
        background:
          "linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%)",
        position: "absolute",
        bottom: 0,
        right: 0,
      },
    },
    bodyContent: {
      "& > p": {
        margin: 0,
      },
    },
    captionContainer: {
      marginTop: "auto",
    },
    caption: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: ({ isMainCard }) =>
        isMainCard ? theme.spacing(3) : theme.spacing(2),
    },
  });
