import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import PageLayout from "@src/layouts/PageLayout";

import BlogCard, { BlogCardProps } from "./BlogCard";
import { BlogPageProps } from "./BlogPage";
import { styles } from "./BlogTemplate.styles";

export type BlogTemplateProps = WithStyles<typeof styles> &
  WithPageData<BlogPageProps> & {
    // If there is no mainPost - it means that there are no posts at all
    mainPost: BlogCardProps;
    posts: BlogCardProps[];
  };

class BlogTemplate extends React.Component<BlogTemplateProps> {
  public render() {
    const { classes, mainPost, posts } = this.props;

    return (
      <PageLayout className={classes.container}>
        <Typography variant="h2" align="center">
          <T message="Blog" />
        </Typography>
        <div className={classes.cardsContainer}>
          <BlogCard
            {...mainPost}
            isMainCard
            className={cn(classes.card, classes.mainCard)}
          />
          {posts.map((post) => (
            <BlogCard key={post.slug} {...post} className={classes.card} />
          ))}
          <div className={cn(classes.fakeCard, classes.card)} />
          <div className={cn(classes.fakeCard, classes.card)} />
        </div>
      </PageLayout>
    );
  }
}

export default withPageData(withStyles(styles)(BlogTemplate));
