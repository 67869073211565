// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import BlogPage from "@src/templates/BlogPage";
export default BlogPage;
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query($initialLocale: String) {
    mainPost: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/^/blog//" } }
        frontmatter: { locale: { eq: $initialLocale } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      nodes {
        frontmatter {
          mainImage
          longPreviewContent
          shortPreviewContent
          category
          title
          author
          date
          locale
        }
        fields {
          slug
        }
      }
    }
    posts: allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/^/blog//" } }
        frontmatter: { locale: { eq: $initialLocale } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: 1
      limit: 12
    ) {
      totalCount
      nodes {
        frontmatter {
          mainImage
          shortPreviewContent
          category
          title
          author
          date
          locale
        }
        fields {
          slug
        }
      }
    }
    authors: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/authors//" } } }
    ) {
      nodes {
        frontmatter {
          id
          firstName
          lastName
          jobTitle
        }
      }
    }
  }
`;
