import {
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import CmsContent from "@src/components/CmsContent";
import FormattedDate from "@src/components/FormattedDate";
import Link from "@src/components/Link";

import { styles } from "./BlogCard.styles";

export type BlogCardProps = {
  className?: string;
  isMainCard?: boolean; // is used in styles
  longPreviewContent: React.ReactNode;
  shortPreviewContent: React.ReactNode;
  mainImage: string;
  category: string;
  title: string;
  author: {
    firstName: string;
    lastName: string;
    jobTitle: string;
  };
  date: Date;
  slug: string;
};

class BlogCard extends React.PureComponent<
  BlogCardProps & WithStyles<typeof styles>
> {
  public render() {
    const { props } = this;
    const { author, classes } = props;

    return (
      <Link to={props.slug} className={props.className}>
        <Card className={classes.cardContainer} elevation={5}>
          <img
            className={classes.image}
            src={props.mainImage}
            alt={props.title}
          />
          <CardContent classes={{ root: classes.cardContent }}>
            <Chip
              color="secondary"
              label={props.category}
              className={classes.category}
            />
            <div className={classes.contentContainer}>
              <Typography
                variant={props.isMainCard ? "h4" : "h6"}
                paragraph
                className={classes.title}>
                {props.title}
              </Typography>
              <div className={classes.body}>
                <CmsContent
                  className={classes.bodyContent}
                  content={
                    props.isMainCard
                      ? props.longPreviewContent
                      : props.shortPreviewContent
                  }
                />
              </div>
              <div className={classes.captionContainer}>
                <Divider />
                <div className={classes.caption}>
                  <Typography variant="subtitle1">
                    {author.firstName} {author.lastName}
                  </Typography>
                  <Typography variant="caption">
                    <FormattedDate date={new Date(props.date)} format="P" />
                  </Typography>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Link>
    );
  }
}

export default withStyles(styles)(BlogCard);
